///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Contact */

	#contact {
		border-bottom: solid 1px _palette(border);
		overflow-x: hidden;

		> .inner {
			@include vendor('display', 'flex');
			padding: 0 !important;

			> :nth-child(2n - 1) {
				@include padding(4em, 0, (0, 3em, 0, 0));
				border-right: solid 1px _palette(border);
				width: 60%;
				z-index: 1;
			}

			> :nth-child(2n) {
				padding-left: 3em;
				width: 40%;
			}

			> .split {
				padding: 0;

				> * {
					@include padding(3em, 0, (0, 0, 0, 3em));
					position: relative;

					&:before {
						border-top: solid 1px _palette(border);
						content: '';
						display: block;
						margin-left: -3em;
						position: absolute;
						top: 0;
						width: calc(100vw + 3em);
					}
				}

				> :first-child {
					&:before {
						display: none;
					}
				}
			}
		}

		@include breakpoint(medium) {
			> .inner {
				display: block;

				> :nth-child(2n - 1) {
					@include padding(4em, 0, (0, 0, 0, 0));
					border-right: 0;
					width: 100%;
				}

				> :nth-child(2n) {
					padding-left: 0;
					width: 100%;
				}

				> .split {
					> * {
						@include padding(3em, 0, (0, 0, 0, 0));

						&:before {
						}
					}

					> :first-child {
						&:before {
							display: block;
						}
					}
				}
			}
		}

		@include breakpoint(small) {
			> .inner {
				> :nth-child(2n - 1) {
					@include padding(3em, 0, (0, 0, 0, 0));
				}
			}
		}
	}