///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

	#main {
		background-color: _palette(bg-alt);

		> * {

			@include inner;
		}

		&.alt {
			background-color: transparent;
		}


    .headerimage {
      position: relative;
      h1 {
        position: absolute;
        top: 60%;
      }
      span.main {
        background: rgb(0, 0, 0);
        background: transparentize(_palette(bg), 0.1);
        padding: 10px;
      }
    }


  }
