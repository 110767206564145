// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.35s,
		transition:			0.2s,
		banner:				2.5s
	);

// Size.
	$size: (
		border-radius:		4px,
		element-height:		2.75em,
		element-margin:		2em,
		inner:				65em
	);

// Font.
	$font: (
		family:				('Roboto', Helvetica, sans-serif),
		family-fixed: ('VT323', monospace),
		weight:				300,
		weight-bold:		 700,
		letter-spacing:		0,
		letter-spacing-alt:	-0.05em,
		letter-spacing-wider:	0.15em
	);

	$font-alt: (
		family:				('Roboto', Helvetica, sans-serif),
	  //family: ('Smooch Sans', sans-serif),
		family-fixed: ('VT323', monospace),
		weight:				300,
		weight-bold:		700,
		letter-spacing:		0, //-0.05em,
		letter-spacing-wider:	0em
	);


// Palette.
	$palette: (
		bg:					#242943,
		//bg-alt:				#2a2f4a,
		//bg:					rgba(146, 39, 143, 1),
		bg-alt:			rgba(146, 39, 143, 1),
		fg:					#ffffff,
		fg-bold:			#ffffff,
		fg-light:			rgba(244,244,255,0.2),
		border:				rgba(212,212,255,0.1),
		border-bg:			rgba(212,212,255,0.035),
		highlight:			#9bf1ff,
		accent1:			#27922c, //#6fc3df,
		accent2:			#8e9227,
		accent3:			#922927,
		accent4:			#e7b788,
		accent5:			#8ea9e8,
		accent6:			#87c5a4
	);
