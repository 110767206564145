///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Type */

	body, input, select, textarea {
		color: _palette(fg);
		font-family: _font(family);
		font-size: 17pt;
		font-weight: _font(weight);
		letter-spacing: _font(letter-spacing);
		line-height: 1.65;

		@include breakpoint(xlarge) {
			font-size: 18pt;
		}

		@include breakpoint(large) {
			font-size: 14pt;
		}

		@include breakpoint(xxsmall) {
			font-size: 10pt;
		}
	}

	a {
		@include vendor('transition', (
			'color #{_duration(transition)} ease-in-out',
			'border-bottom-color #{_duration(transition)} ease-in-out'
		));
		border-bottom: dotted 1px;
		color: inherit;
		text-decoration: none;

		&:hover {
			border-bottom-color: transparent;
			color: _palette(highlight) !important;
		}

		&:active {
			color: desaturate(darken(_palette(highlight), 15), 5) !important;
		}
	}

	strong, b {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 (_size(element-margin) * 0.5) 0;
	}

	blockquote > p {
		margin: 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
		line-height: 1.25;
		margin: (_size(element-margin) * 2) 0 0 0;

		a {
			color: inherit;
			border-bottom: 0;
		}
	}

	h1 {
		margin: 0 0 0 0;
		font-size: 3em;
	}

	h2 {
		font-size: 2.55em;
	}

	h3 {
		font-size: 1.7em;
	}

	h4 {
		font-size: 1.5em;
	}

	h5 {
		font-size: 1.2em;
	}

	h6 {
		font-size: 0.9em;
	}

	@include breakpoint(small) {
		h1 {
			font-size: 2em;
		}

		h2 {
			font-size: 1.5em;
		}

		h3 {
			font-size: 1.25em;
		}
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px _palette(border);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

	code {
		background: _palette(border-bg);
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid 1px _palette(border);
		margin: _size(element-margin) 0;

		&.major {
			margin: (_size(element-margin) * 1.5) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}


	.alt {
			font-weight: _font_alt(weight);
			font-family: _font_alt(family);
			letter-spacing: _font_alt(letter-spacing);

			//text-transform: uppercase;
		}

	.reverse {
		color: _palette(fg-bold);
		background-color: _palette(bg-alt);
		line-height: 1.15;
	}

	.altheavy {
			font-family: _font_alt(family);
			letter-spacing: _font_alt(letter-spacing);
			text-transform: uppercase;
			font-weight: _font_alt(weight-bold);
	}
