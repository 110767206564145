///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Contact Method */

	.contact-method {
		margin: 0 0 _size(element-margin) 0;
		padding-left: 3.25em;
		position: relative;

		.icon {
			left: 0;
			position: absolute;
			top: 0;
		}

		h3 {
			margin: 0 0 (_size(element-margin) * 0.25) 0;
		}
	}